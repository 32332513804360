<template>
    <v-list three-line class="stores-container" :style="{height: height + 'px'}">
        <draggable
            handle=".handle"
            v-bind="{ group: 'ITEMS' }"
            v-model="filterItems"
            @change="change"
            style="height:100%"
        >
            <v-list-item
                v-for="store in filterItems"
                :key="store.IDCliente"
                :disabled="readonly"
            >
                <v-list-item-action>
                    <v-icon class="handle">mdi-drag</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title
                        v-html="
                            `${store.AliasCliente} ${store.Insegna}`
                        "
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-html="
                            `${store.IndirizzoInsegna}`
                        "
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                        v-html="
                            `${store.CAPInsegna} ${store.ComuneInsegna} (${store.SiglaProvinciaInsegna})`
                        "
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </draggable>
    </v-list>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    props: ["stores", 'search', 'readonly', 'height'],

    data: () => ({
    }),

    components: {
        draggable
    },

    computed: {
        filterItems: {
            get: function() {
                const filteredStores = this.stores.filter(store => {
                    const checkGLNFilter =
                        store.CodiceNazionaleCoopGLN.length !== 0;
                    const checkText =
                        this.search.length === 0
                            ? true
                            : store.Insegna.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              store.AliasCliente.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              store.IndirizzoInsegna.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              store.CAPInsegna.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              store.ComuneInsegna.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              ('(' + store.SiglaProvinciaInsegna + ')').toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              store.CodiceNazionaleCoopGLN.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              store.CodiceClienteEsterno.toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1 ||
                              store.IDCliente.toString().toLowerCase().indexOf(
                                  this.search.toLowerCase()
                              ) !== -1;



                    return checkGLNFilter && checkText;
                });
                this.$emit('currentSelection', filteredStores);
                return filteredStores;
            },

            set: function(newValues) {
            }
        }
    },

    methods: {
        change(evt) {
            /* eslint-disable no-empty */
            // console.log(evt, Object.keys(evt))
            const action = Object.keys(evt)[0]
            if (action !== 'added' && action !== 'removed') {
                return
            }
            const element = evt[action].element
            if (action === 'added') {
                const newStores = this.stores
                const index = evt[action].newIndex
                newStores.splice(index, 0, element)
                this.$emit('update:stores', newStores)
            }
            else { // removed
                // console.log(evt[action].element)
                const newStores = this.stores.filter(store => {
                    return store.IDCliente !== element.IDCliente
                })
                this.$emit('update:stores', newStores)
            }
        }
    }
};
</script>

<style scoped>
.stores-container {
/*    height: 435px;*/
    overflow-y: scroll;
    padding-bottom: 96px;
}

.v-list-item {
    cursor: pointer;
    user-select: none;
}

.v-list-item__action {
    margin-right: 16px !important;
}

/* .handle {
    cursor: move !important;
    cursor: -webkit-grabbing !important;
} */

.handle:hover {
    cursor: pointer;
}

.changed {
    background: rgba(255,0,0,0.1);
}
</style>
